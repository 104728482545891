import styles from './Link.css'

export function LinkUnderline({ href, dataX, children, ariaLabel = undefined, layoutClassName = undefined }) {
  return (
    <LinkBase
      className={cx(styles.componentUnderline, layoutClassName)}
      {...{ dataX, ariaLabel, href }}
    >
      {children}
    </LinkBase>
  )
}

export function LinkUnderlineOnHover({ href, dataX, children, ariaLabel = undefined, layoutClassName = undefined }) {
  return (
    <LinkBase
      className={cx(styles.componentUnderlineOnHover, layoutClassName)}
      {...{ dataX, ariaLabel, href }}
    >
      {children}
    </LinkBase>
  )
}

export function Link({ href, dataX, children, ariaLabel = undefined, layoutClassName = undefined }) {
  return (
    <LinkBase
      className={cx(styles.component, layoutClassName)}
      {...{ dataX, ariaLabel, href }}
    >
      {children}
    </LinkBase>
  )
}

function LinkBase({ href, dataX, ariaLabel, children, className }) {
  const protocols = ['http', 'https', 'tel', 'mailto']
  const hasProtocol = protocols.some(x => href.startsWith(x))
  const target = hasProtocol ? '_blank' : '_self'
  const rel = hasProtocol ? 'noopener noreferrer' : null

  return (
    <a
      data-x={dataX}
      className={cx(styles.componentBase, className)}
      {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
      {...{ href, rel, target }}
    >
      {children}
    </a>
  )
}
