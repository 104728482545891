import { PortableText } from '@portabletext/react'
import { routeMap } from '/routeMap'
import { LinkUnderline } from '/features/buildingBlocks/Link'
import { HeadingSm, HeadingLg, HeadingXl } from '/features/buildingBlocks/Heading'
import { TextSm } from '/features/buildingBlocks/Text'
import { refToDataXLink } from '/machinery/tracking/pushToDataLayer'
import { determineDocumentPathSyncWithParams } from '/machinery/determineDocumentPathSyncWithParams'

import styles from './PortableText.css'

export function PortableTextMainContent({ value }) {
  return (
    <PortableTextBase
      className={styles.componentMainContent}
      components={componentsMainContent}
      {...{ value }}
    />
  )
}

export function PortableTextCheckmarks({ value }) {
  return (
    <PortableTextBase
      className={styles.componentCheckmarks}
      components={componentsCheckmarks}
      {...{ value }}
    />
  )
}

export function PortableTextCleanHtml({ blocks }) {
  return <PortableText value={blocks} components={componentsSimpleCleanHtml} />
}

function PortableTextBase({ value, components, className }) {
  return (
    <div className={cx(styles.componentBase, className)}>
      <PortableText {...{ value, components }} />
    </div>
  )
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
const componentsSimpleCleanHtml = {
  list: {
    bullet: ({ children }) => <ul>{children}</ul>,
    number: ({ children }) => <ol>{children}</ol>,
  },
  listItem: (props) => <li>{props.children}</li>,
  marks: {
    strong: ({ children }) => <strong>{children}</strong>,
    em: ({ children }) => <em>{children}</em>,
    underlined: ({ children }) => <u>{children}</u>,
    internalLink: ({ value, children }) => <InternalLinkClean {...{ value, children }} />,
    externalLink: ({ value, children }) => <a data-x='link' href={value.href}>{children}</a>,
  },
  block: {
    normal: ({ children }) => <p>{children}</p>,
    heading: ({ children }) => <h2>{children}</h2>,
    subheading: ({ children }) => <h3>{children}</h3>,
  },
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
export const componentsMainContent = {
  block: {
    h2: ({ children }) => <HeadingSm h='2' title={children} layoutClassName={styles.headingLayout} />,
    normal: ({ children }) => <TextSm text={children} />,
    heading: ({ children }) => <HeadingXl h='2' title={children} layoutClassName={styles.headingLayout} />,
    subheading: ({ children }) => <HeadingSm h='3' title={children} layoutClassName={styles.subheadingLayout} />,
  },
  list: {
    bullet: ({ children }) => <ul className={cx(styles.list, styles.listUnordered)}>{children}</ul>,
    number: ({ children }) => <ol className={cx(styles.list, styles.listOrdered)}>{children}</ol>,
  },
  listItem: ({ children }) => <li className={styles.listItem}>{children}</li>,
  marks: {
    strong: ({ children }) => <strong>{children}</strong>,
    em: ({ children }) => <em className={styles.em}>{children}</em>,
    externalLink: ({ value, children }) => <LinkUnderline href={value.href} dataX='link'>{children}</LinkUnderline>,
    internalLink: ({ value, children }) => <InternalLink {...{ value, children }} />,
  },
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
export const componentsCheckmarks = {
  block: {
    normal: ({ children }) => <p className={styles.paragraph}>{children}</p>,
    heading: ({ children }) => <HeadingLg h='2' title={children} layoutClassName={styles.headingLayout} />,
    subheading: ({ children }) => <HeadingSm h='3' title={children} layoutClassName={styles.subheadingLayout} />,
  },
  list: {
    bullet: ({ children }) => <ul className={cx(styles.list, styles.listChecklist)}>{children}</ul>,
    number: ({ children }) => <ol className={cx(styles.list, styles.listOrdered)}>{children}</ol>,
  },
  listItem: ({ children }) => <li className={styles.listItem}>{children}</li>,
  marks: {
    strong: ({ children }) => <strong>{children}</strong>,
    em: ({ children }) => <em>{children}</em>,
    externalLink: ({ value, children }) => <LinkUnderline href={value.href} dataX='link'>{children}</LinkUnderline>,
    internalLink: ({ value, children }) => <InternalLink {...{ value, children }} />,
  },
}

function InternalLink({ value, children }) {
  return value.ref
    ? (
      <LinkUnderline
        href={determineDocumentPathSyncWithParams({ document: value.ref, routeMap, params: value.params })}
        dataX={refToDataXLink(value.ref)}
      >
        {children}
      </LinkUnderline>
    )
    : <>{children}</>
}

function InternalLinkClean({ value, children }) {
  const { ref, params } = value
  return ref
    ? (
      <a data-x={refToDataXLink(ref)} href={determineDocumentPathSyncWithParams({ document: ref, routeMap, params })}>
        {children}
      </a>
    ) : <>{children}</>
}
